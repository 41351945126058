<script>
import axios from 'axios'
import Title from '../components/Title.jsx'
import SubTitle from '../components/SubTitle.jsx'
import List from '../components/List'
import TeacherList from '../components/TeacherList'
import Footer from '../components/Footer'
export default {
  // 在 Vue 中使用JSX，我们不再需要注册所需的每个组件。 我们只是导入和使用
  // components: {
  //   Title,
  //   SubTitle,
  //   List
  // },
  data() {
    return {
      teacherData: {

      }
    }
  },
  methods: {
    imgRender() {
      this.$nextTick(() => {
        let img = document.querySelector('.bg-img')
        if (img.complete) {
          setTimeout(() => {
            this.$toImage.domtoimg(document.getElementById('imgBox'))
          }, 1000)
        } else {
          img.addEventListener('load', () => {
            this.$toImage.domtoimg(document.getElementById('imgBox'))
          })
        }
      })
    },
    //避免重复载入 
    removeRemoteCss(href) {
      const linkList = document.querySelectorAll('#remote_css')
      console.log(linkList);
      for (var i = 0; i < linkList.length; i++) {
        linkList[i].parentNode.removeChild(linkList[i]);
      }
    },
    // 创建link标签引用远程css
    createCssLink(css_name) {
      this.removeRemoteCss()
      const link = document.createElement('link')
      link.type = 'text/css'
      link.rel = 'stylesheet'
      link.id = 'remote_css'
      const cssPath = `https://dev.design.ceba.ceshiren.com/backend/css/${css_name}.css`
      link.href = cssPath
      document.head.appendChild(link)
      console.log('css加载完成');
      this.imgRender()
    },
    // 获取数据
    getData() {
      this.loading = true
      // axios.get(`${location.origin}/backend/config/${this.$route.query.config}`)
      axios.get(`https://dev.design.ceba.ceshiren.com/backend/config/${this.$route.query.config}`)
        .then(res => {
          console.log(res);
          if (res.data.data) {
            this.teacherData = res.data.data
            if (!res.data.data.css) {
              this.createCssLink(res.data.data.css)
            }
            else {
              this.imgRender()
            }

          }

        })
        .catch(err => {
          this.loading = false
          console.log(err);
        })
    },
    alertMessage() {
      alert('父组件事件')
    }
  },
  created() {
    this.getData()
  },
  mounted() {

  },
  render(h) {
    console.log(this.teacherData.teacher_introduce);
    const allTem ={
      TitleTem : <Title val={this.teacherData.title} />,
      SubTitleTem : <SubTitle val={this.teacherData.subTitle} />,
      ListTem : <List val={this.teacherData.contentList_top} />,
      TeacherListTem : <TeacherList val={this.teacherData.teacher_introduce} />,
      FooterTem : <Footer val={this.teacherData.footerList_} qrcode_url={this.teacherData.qrcode_url} onparent={() => this.alertMessage()} />
    }
    const data = [{template:'TitleTem'},{template:'ListTem'},{template:'TeacherListTem'},{template:'FooterTem'}]
    return (
      <div class="default lecture-introduce">
        <div
          class="box"
          id="imgBox"
        >
          <img
            src={this.teacherData.background_img}
            class={"bg-img"}
            style={{ maxWidth: '420px', }}
          />
          <div class={'main-box'}>
            {data.map((item,index) => allTem[item.template] )}
          </div>
        </div>
      </div >
    )
  }
}
</script>

<style scoped>
.default {
  display: flex;
  justify-content: center;
}
.box {
  position: relative;
  display: inline-block;
  line-height: 0;
}
.bg-img {
  width: 100%;
  object-fit: contain;
}
.main-box {
  position: absolute;
  top: 0;
  width: 100%;
  padding-top: 20%;
}
</style>