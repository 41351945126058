import '../style/footer.css'
import VueQr from 'vue-qr'
export default {
  components: {
    VueQr
  },
  functional: true,
  render(h, content) {
    console.log(content.props);
    let list = []
    let qrcode_url = ''
    if (content.props.val) list = content.props.val; qrcode_url = content.props.qrcode_url
    return (
      <div class={'footer'}>
        <div>
          {list.map((item, index) =>
            <div class={'footerlist-item'} domPropsInnerHTML={item.text}></div>
          )}
        </div>
        {qrcode_url && <div class={'footer-box-right'} onClick={() => content.listeners.parent()}>
          <VueQr text={qrcode_url} size={75} margin={2}></VueQr>
        </div>}
      </div>
    )
  }
}