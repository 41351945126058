import '../style/teacherList.css'
export default {
  functional: true,
  render(h, content) {
    let teacher = {}
    let list = []
    if (content.props.val) {
      teacher = content.props.val; list = content.props.val.introduce
    }
    console.log('teacherList', list);
    return (
      <div class={'content-box-bottom'} id={'content-box-bottom'}>
        <div class={'tag'}>讲师介绍</div>
        <div class={'content'} style={{ paddingLeft: '20px' }}>
          {teacher.img &&
            <div class={'img-box'}>
              <img src={teacher.img} />
            </div>
          }
          <div class={'content-text'} >
            {list?.map((item, index) => <div>
              {item?.name && <span class={['teacher-name', `teacher-name${index}`]} domPropsInnerHTML={item.name}></span>}
              <span class={['teacher-introduce', `teacher-introduce${index}`]} domPropsInnerHTML={item.text}></span>
            </div>
            )}
          </div>
        </div>
      </div>
    )
  }
}