import '../style/title.css'
export default {
  functional: true, //函数式组件
  render(h,content) {
    // 函数式组件是没有this的。所有的内容都在render的第二个参数里
    console.log("content", content);
    return (
      <div class={'title-box'} domPropsInnerHTML={content.props.val}></div>
    )
  }
}