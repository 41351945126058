import '../style/list.css'
export default {
  functional: true,
  // name: 'List',
  // data() {
  //   return {

  //   }
  // },
  // props:{
  //   list:[]
  // },
  render(h, content) {
    let list = []
    if (content.props.val) list = content.props.val
    return (
      <div class={'content-box-top'}>
        <div class="tag">课程要点</div>
        {list.map((item, index) =>
          <div class={['content-item', `content-item${index}`]} >
            <div class={'content-item-icon'}>
              <ul>
                <li></li>
              </ul>
            </div>
            { item.text }
          </div>)}
      </div>
    )
  }
}
